import { Link } from "react-router-dom"
import heroSecion from "./assets/hero-image.png"
import heroSecionBanner from "./assets/hero-section-bg.jpg"
import ZayaraForumFooter from "./components/Footer"
import { ArrowIcon } from "./components/Icons"
import { forumGroupsList } from "./static-data"
import { useSBSState } from "context/global"
import Locale from 'translations'
import ZayaraForumHeader from './components/Header'


export default function ZayaraForumLanding() {
  const { locale } = useSBSState();
  const { umarhForum } = Locale;



  return (
    <main className="zayara-forum">
      <section className="hero-section">
        <div className="hero-banner">
          <img src={heroSecionBanner} alt="umrah forum bg" height="644px" />
        </div>


        <div className="container">
          <ZayaraForumHeader />
          <div className="content-section row mx-0">
            <div className="align-self-center  col-md-6 col-12">
              <h1 className="d-flex flex-column flex-wrap gap-10">
                <span>{umarhForum.participateInTheFutureUmrah} </span>
                <span>{umarhForum.exclusiveFocusGroups}</span>
              </h1>
              <p>{umarhForum.joinUsAtUmrahZiyarahForum}</p>
              <span></span>
            </div>
            <img src={heroSecion} alt="منتدي العمرة و الزيارة" className="img-fluid hero-image col-md-6 col-12" />

          </div>
        </div>
      </section>


      <section className="landing-groups-container">
        <div className="container">
          <h2 className="title">{umarhForum.focusGroups}</h2>
          <p className="description">
            {umarhForum.focusGroupsTitlePragraph}
          </p>

          <ul className="groups-list my-5">
            {forumGroupsList.map(group => {
              return (
                <li className="item" key={group.url}>
                  <span className="icon">{group.icon}</span>
                  <h4 className="group-title">{group.title?.[locale]}</h4>
                  <Link to={`/umrah-forum/group/${group.url}`} className="d-flex justify-content-center align-items-center gap-10 text-center mt-3">
                    <span>{umarhForum.aboutFocusGroup}</span>
                    <span className="mirror-rtl">
                      <ArrowIcon />
                    </span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </section>

      <ZayaraForumFooter />
    </main>
  )
}
