import { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import ZayaraForumFooter from "./components/Footer";
import InnerPageHeroSection from "./components/InnerPageHeroSection";
import { forumGroupsList } from "./static-data";
import { useSBSState } from 'context/global';
import Locale from 'translations';

export default function ZayaraForumFocusGroupPage() {
  const { umarhForum } = Locale;

  const { locale } = useSBSState();
  const { group_id } = useParams();
  const pageData = forumGroupsList.find(group => group.url === group_id);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [])

  const title = pageData.title?.[locale] || pageData.title.en;
  const discussion_topics = pageData.discussion_topics?.[locale] || pageData.discussion_topics.en


  return (
    <main className="zayara-forum inner-page">
      <InnerPageHeroSection title={title} />


      <section className="landing-groups-container">
        <div className="container">
          <div className="row mx-0">
            <div className="col-md-8 col-12">
              <h2 className="title group-title">{umarhForum.aboutFocusGroup}</h2>
              <p className="description">{pageData.description?.[locale] || pageData.description.en}</p>
            </div>
            <div className="col-md-4 col-12">
              <img src={pageData.image} alt={title} className="img-fluid d-none d-md-block" />
            </div>
          </div>

          <div className="discussion_topics mt-md-5 mt-3">
            <h3 className="title"> {umarhForum.discussionTopics}</h3>
            <ul className="d-flex flex-column justify-content-center align-items-center gap-10">
              {discussion_topics.map(topic => {
                return (
                  <li key={topic} className="rounded-lg px-2 text-center  w-100 border" style={{ maxWidth: "700px", borderColor: "#DDDCDC", paddingBlock: "10px" }}>
                    {topic}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </section>

      <ZayaraForumFooter group={pageData.url} />
    </main>
  )
}
