import {
  // CrowdManagementIcon,
  // EnhancingUmrahIcon,
  ImprovementIcon,
  InnovationIcon,
  IOTIcon,
  LogisticsIcon,
  // RaisingUmrahLevelIcon,
  UmrahAccommodationsIcon,
  UmrahPlusIcon
} from './components/Icons';

import umrahSmartSolution from "./assets/groups/umrah-smart-solution.jpg"
import logistics from "./assets/groups/logistics.jpg"
import accommodations from "./assets/groups/accommodations.jpg"
// import crowdManagement from "./assets/groups/crowd-management.jpg"
// import enhancingUmrahExperience from "./assets/groups/enhancing-umrah-experience.jpg"
import improvement from "./assets/groups/improvement.jpg"
import innovation from "./assets/groups/innovation.jpg"
// import raisingUmrahService from "./assets/groups/raising-umrah-service-level.jpg"
import umrahPlus from "./assets/groups/umrah-plus.jpg"



export const forumGroupsList = [
  // umrah-smart-solutions
  {
    title: {
      en: "Smart Solutions for Umrah: Using the Internet of Things and Big Data",
      ar: "⁠الحلول الذكية للعمرة: استخدام إنترنت الأشياء والبيانات الضخمة"
    },
    description: {
      en: `The use of big data and the Internet of Things (IoT) has become a key factor in enhancing the Umrah experience. From predicting crowd movement to customizing services based on the needs of pilgrims, modern technologies enable more efficient and flexible services. In this group, we will focus on how big data and IoT can improve the management and planning of Umrah trips, with real-world examples of smart solutions already implemented in different countries, and how these models can be leveraged to enhance the Umrah experience, from planning and travel to waiting at the airport, performing the rituals, and returning.`,
      ar: `
      أصبح استخدام البيانات الضخمة وإنترنت الأشياء (IoT) عاملاً رئيسيًا في تحسين تجربة العمرة. من التنبؤ بحركة الحشود إلى تخصيص الخدمات بناءً على احتياجات المعتمرين، تتيح التقنيات الحديثة تقديم خدمات أكثر كفاءة ومرونة.
      في هذه المجموعة، سنركز على  كيفية استخدام البيانات الضخمة وإنترنت الأشياء لتحسين إدارة وتخطيط رحلات العمرة، بأمثلة واقعية لحلول ذكية مطبقة بالفعل من مختلف دول العالم، وكيف يمكن الاستفادة من تلك النماذج لتحسين تجربة العمرة بداية من التخطيط والسفر والانتظار في المطار وحتى إقامة المناسك والعودة.
      `
    },
    discussion_topics: {
      en: [
        "How can the Internet of Things contribute to improving Umrah services?",
        "What role does big data play in providing a more personalized experience for pilgrims?",
        "How can technology be used to improve crowd management and operational services?",
        "What are the investment opportunities in smart solutions for Umrah for businesses? ",
      ],
      ar: [
        "كيف يمكن أن يساهم إنترنت الأشياء في تحسين خدمات العمرة؟",
        "ما دور البيانات الضخمة في تقديم تجربة أكثر تخصيصًا للمعتمرين؟",
        "كيف يمكن استخدام التكنولوجيا في تحسين إدارة الحشود والخدمات التشغيلية؟",
        "ما هي فرص الاستثمار في الحلول الذكية للعمرة للشركات؟"
      ],
    },
    image: umrahSmartSolution,
    icon: <IOTIcon />,
    url: "umrah-smart-solutions"
  },
  // logistics
  {
    title: {
      en: "Innovating Logistical Solutions for Air Travel to Perform Umrah",
      ar: "⁠ابتكار حلول لوجستية للسفر الجوي لأداء العمرة"
    },
    description: {
      en: `Air travel is a fundamental pillar of the Umrah journey, requiring high-level coordination between airlines, airports, and organizing entities to ensure a smooth experience for pilgrims. With the annual increase in Umrah travellers, there is a growing need for innovative logistical solutions that enhance flight management, reduce waiting times, and offer more efficient and flexible travel options. Modern technologies such as artificial intelligence, advanced analytics, and the integration of booking and ground transportation systems contribute to operational efficiency and a better pilgrim experience—from booking to arrival.`,
      ar: `يُعد السفر الجوي أحد الركائز الأساسية في رحلة العمرة، حيث يتطلب تنسيقًا عاليًا بين شركات الطيران، والمطارات، والجهات المنظمة لضمان تجربة سلسة للمعتمرين. مع ازدياد أعداد المعتمرين سنويًا، تبرز الحاجة إلى حلول لوجستية مبتكرة تُحسّن إدارة الرحلات، تقلل أوقات الانتظار، وتوفر خيارات سفر أكثر كفاءة ومرونة. التقنيات الحديثة مثل الذكاء الاصطناعي، والتحليلات المتقدمة، والتكامل بين أنظمة الحجز والنقل البري، تسهم في رفع كفاءة العمليات وتقديم تجربة أفضل للمعتمرين من لحظة الحجز وحتى الوصول.`,
    },
    discussion_topics: {
      en: [
        "How can the air travel experience for Umrah pilgrims be improved through digital transformation and modern technologies?",
        "What are the best practices for managing seasonal Umrah flights and reducing airport congestion?",
        "What smart solutions can accelerate travel and immigration procedures?",
        "How can partnerships and innovations enhance the air travel experience?",
      ],
      ar: [
        "كيف يمكن تحسين تجربة السفر الجوي للمعتمرين من خلال التحول الرقمي والتقنيات الحديثة؟",
        "ما هي أفضل الممارسات لإدارة الرحلات الموسمية للعمرة وتقليل الازدحام في المطارات؟",
        "ما الحلول الذكية التي يمكن أن تسرّع إجراءات السفر والجوازات؟",
        "كيف يمكن تحسين تجربة السفر الجوي من خلال الشراكات والابتكارات؟"
      ]
    },
    image: logistics,
    icon: <LogisticsIcon />,
    url: "logistics"
  },
  // improvement
  {
    title: {
      en: "Improving Operational Systems for Umrah",
      ar: "تحسين النظم التشغيلية للعمرة"
    },
    description: {
      en: "Umrah operations require complex management of visas, bookings, transportation, accommodation, and on-ground services. Enhancing operational systems aims to boost efficiency, reduce errors, and improve pilgrim satisfaction through the adoption of modern technologies such as automation, advanced analytics, and artificial intelligence. Strengthening integration between stakeholders and developing comprehensive digital systems leads to smoother and more flexible services, significantly enhancing the overall Umrah experience.",
      ar: `
    تتطلب عمليات العمرة إدارة معقدة تشمل التأشيرات، الحجوزات، النقل، الإقامة، والخدمات الميدانية. يهدف تحسين النظم التشغيلية إلى تعزيز الكفاءة وتقليل الأخطاء وزيادة رضا المعتمرين، من خلال تبني التقنيات الحديثة مثل الأتمتة، والتحليلات المتقدمة، والذكاء الاصطناعي. تحسين التكامل بين الجهات المعنية، وتطوير أنظمة رقمية متكاملة، يسهم في تقديم خدمات أكثر سلاسة ومرونة، مما يعزز تجربة العمرة بشكل عام.
      `
    },
    discussion_topics: {
      en: [
        "How can digital transformation improve the operational efficiency of Umrah services?",
        "What role do digital technologies and AI play in reducing costs and speeding up processes?",
        "How can the integration between booking, visa, and transport systems be improved to ensure a seamless experience?",
        "What are the key operational challenges in the Umrah sector, and how can technology address them?"
      ],
      ar: [
        "كيف يمكن للتحول الرقمي أن يسهم في رفع كفاءة العمليات التشغيلية للعمرة؟",
        "ما دور التحول الرقمي والذكاء الاصطناعي في تقليل التكاليف وزيادة سرعة تنفيذ الإجراءات؟",
        "كيف يمكن تحسين التكامل بين أنظمة الحجوزات، التأشيرات، والنقل لضمان تجربة سلسة للمعتمرين؟",
        "ما هي أبرز التحديات التشغيلية التي تواجه قطاع العمرة، وكيف يمكن التغلب عليها باستخدام التكنولوجيا؟"
      ]
    },
    image: improvement,
    icon: <ImprovementIcon />,
    url: "improvement"
  },
  // innovation
  {
    title: {
      en: "Enhancing Innovation and Entrepreneurship in Umrah",
      ar: "تعزيز الابتكار وريادة الأعمال في العمرة"
    },
    description: {
      en: "The Umrah sector is constantly evolving and demands innovative solutions to meet the growing needs of pilgrims. Through entrepreneurship and advanced technologies, services can be enhanced, and new solutions developed to ease the pilgrim journey. This group will explore the Umrah ecosystem, understand the role of innovation in overcoming challenges, and present entrepreneurial ideas that improve operational efficiency and service quality. The session will include a brainstorming segment to develop innovative initiatives that address major issues faced by pilgrims.",
      ar: `يشهد قطاع العمرة تطورًا مستمرًا يتطلب حلولًا مبتكرة لمواكبة الاحتياجات المتزايدة لضيوف الرحمن. من خلال ريادة الأعمال والتقنيات الحديثة، يمكن تحسين الخدمات المقدمة، وتطوير حلول جديدة تساهم في تسهيل رحلة المعتمر. تركز هذه المجموعة على استكشاف بيئة عمل العمرة (Ecosystem)، وفهم دور الابتكار في مواجهة التحديات، وطرح أفكار ريادية تعزز من كفاءة العمليات وجودة الخدمات. كما ستشمل الجلسة جلسة عصف ذهني لتطوير مبادرات مبتكرة لحل المشكلات الرئيسية التي يواجهها المعتمرون.`
    },
    discussion_topics: {
      en: [
        "How can entrepreneurs provide innovative solutions that enhance the pilgrim experience?",
        "What are the main challenges facing the Umrah sector today, and how can technology address them?",
        "How can a supportive ecosystem for innovation and investment in the Umrah sector be developed?",
        "What practical steps can be taken to transform entrepreneurial ideas into successful projects in the Umrah field?"
      ],
      ar: [
        "كيف يمكن لرواد الأعمال تقديم حلول مبتكرة تعزز من تجربة المعتمرين؟",
        "ما هي أبرز التحديات التي تواجه قطاع العمرة اليوم، وكيف يمكن للتقنيات الحديثة معالجتها؟",
        "كيف يمكن تطوير بيئة داعمة للابتكار والاستثمار في قطاع العمرة؟",
        "ما الخطوات العملية التي يمكن اتخاذها لتحويل الأفكار الريادية إلى مشاريع ناجحة في مجال العمرة؟"
      ]
    },
    image: innovation,
    icon: <InnovationIcon />,
    url: "innovation"
  },
  // umrah-plus
  {
    title: {
      en: "Umrah Plus + Direct Marketing and Enhancing the Concept of Direct Sales to the Customer",
      ar: "العمرة بلس + التسويق المباشر وتعزيز مفهوم البيع المباشر للعميل"
    },
    description: {
      en: "In the digital age, direct marketing has become an effective tool to enhance the Umrah experience and attract pilgrims through more personalized and impactful approaches. The “Umrah Plus +” concept focuses on developing marketing strategies that prioritize direct-to-consumer sales, enabling Umrah companies to offer tailored packages and services without relying on intermediaries. This includes leveraging e-commerce, smart booking apps, and loyalty programs to improve customer experience and retention. Advanced analytics and digital marketing technologies also enhance targeting accuracy and efficiency.",
      ar: `في عصر التحول الرقمي، أصبح التسويق المباشر أداة فعالة لتعزيز تجربة العمرة وجذب المعتمرين بأساليب أكثر تخصيصًا وتأثيرًا. يهدف مفهوم “العمرة بلس +” إلى تطوير استراتيجيات تسويقية تركز على البيع المباشر للعميل، مما يمكن شركات العمرة من تقديم عروض وخدمات مخصصة دون الحاجة إلى وسطاء. يشمل ذلك الاستفادة من التجارة الإلكترونية، والحجوزات عبر التطبيقات الذكية، وبرامج الولاء، مما يسهم في تحسين تجربة العميل وزيادة ولائه. كما أن استخدام التحليلات المتقدمة وتقنيات التسويق الرقمي يعزز القدرة على استهداف شرائح المعتمرين بدقة وكفاءة.`
    },
    discussion_topics: {
      en: [
        "How can Umrah companies adopt direct sales strategies to boost customer loyalty and revenue?",
        "What is the role of digital platforms in facilitating Umrah bookings and enhancing the pilgrim experience?",
        "What are the most effective channels for reaching potential customers?",
        "How can trust in direct online bookings be strengthened among pilgrims?"
      ],
      ar: [
        "كيف يمكن لشركات العمرة تبني استراتيجيات البيع المباشر لتعزيز ولاء العملاء وزيادة الإيرادات؟",
        "ما دور المنصات الرقمية في تسهيل حجز رحلات العمرة وتحسين تجربة المعتمر؟",
        "ما القنوات الأكثر فاعلية للتواصل مع العملاء المحتملين؟",
        "كيف يمكن تعزيز ثقة المعتمرين في الحجز المباشر عبر الإنترنت؟"
      ]
    },
    image: umrahPlus,
    icon: <UmrahPlusIcon />,
    url: "umrah-plus"
  },
  // crowd-management
  // {
  //   title: {
  //     en: "Crowd Management Outside the Two Holy Mosques",
  //     ar: "إدارة الحشود خارج الحرمين"
  //   },
  //   description: {
  //     en: "With the increasing number of Umrah pilgrims annually, managing crowds outside the Two Holy Mosques is a critical challenge to ensure the safety and comfort of visitors. This requires innovative solutions that combine smart data analytics, intelligent guidance systems, and integration between transportation methods and infrastructure to ensure smooth crowd flow and reduce congestion. Technologies such as AI, IoT, and real-time monitoring can improve crowd distribution, provide rapid emergency response, and enhance the overall Umrah experience.",
  //     ar: `مع تزايد أعداد المعتمرين سنويًا، تصبح إدارة الحشود خارج الحرمين الشريفين تحديًا رئيسيًا لضمان سلامة وراحة الزوار. تتطلب هذه العملية حلولًا مبتكرة تجمع بين التحليل الذكي للبيانات، وأنظمة التوجيه الذكية، والتكامل بين وسائل النقل والبنية التحتية، لضمان تدفق سلس للحشود وتقليل الازدحام. استخدام تقنيات مثل الذكاء الاصطناعي، وإنترنت الأشياء، والمراقبة الفورية يمكن أن يساعد في تحسين توزيع الحشود، والاستجابة الفورية لحالات الطوارئ، وتعزيز تجربة العمرة بشكل عام.`
  //   },
  //   discussion_topics: {
  //     en: [
  //       "How can modern technology be utilized to improve crowd management outside the Holy Mosques?",
  //       "What global best practices can be applied to organize the flow of pilgrims and reduce congestion?",
  //       "How can transportation systems be better integrated with crowd management plans to ensure a smoother experience?",
  //       "What role do big data and AI play in predicting pilgrim movement and improving distribution?"
  //     ],
  //     ar: [
  //       "كيف يمكن توظيف التكنولوجيا الحديثة في تحسين إدارة الحشود خارج الحرمين؟",
  //       "ما هي أفضل الممارسات العالمية التي يمكن تطبيقها لتنظيم تدفق المعتمرين وتقليل الازدحام؟",
  //       "كيف يمكن تحسين تكامل أنظمة النقل مع خطط إدارة الحشود لتوفير تجربة أكثر سلاسة؟",
  //       "ما دور البيانات الضخمة والذكاء الاصطناعي في التنبؤ بحركة المعتمرين وتحسين توزيعهم؟"
  //     ]
  //   },
  //   image: crowdManagement,
  //   icon: <CrowdManagementIcon />,
  //   url: "crowd-management"
  // },

  // // raising-umrah-service-level
  // {
  //   title: {
  //     en: "Elevating Umrah Services (Excellence in Hospitality)",
  //     ar: "رفع مستوى خدمات العمرة (التميز في الضيافة)"
  //   },
  //   description: {
  //     en: "As competition in religious tourism intensifies, excellence in hospitality has become essential in attracting high-end visitors to Saudi Arabia. This group focuses on exploring market opportunities for VIP services and how to design luxurious Umrah experiences that meet the needs of travelers seeking premium services. Discussions will cover designing high-end hospitality packages, best practices in personalizing the Umrah journey, and showcasing successful VIP service models for pilgrims.",
  //     ar: `مع ازدياد التنافس في قطاع السياحة الدينية، أصبح التميز في الضيافة عنصرًا أساسيًا لجذب الزوار المميزين إلى السعودية. تركز هذه المجموعة على استكشاف الفرص المتاحة في السوق لخدمات VIP، وكيفية تقديم تجربة عمرة فاخرة تلبي احتياجات المسافرين الباحثين عن خدمات راقية. سنناقش آليات تصميم باقات ضيافة متميزة، وأفضل الممارسات في تخصيص تجربة العمرة، مع عرض نموذج ناجح في تقديم خدمات VIP لضيوف الرحمن.`
  //   },
  //   discussion_topics: {
  //     en: [
  //       "How can a luxurious Umrah experience be delivered for discerning visitors seeking high-end services?",
  //       "What market opportunities exist to attract affluent travelers through VIP offerings?",
  //       "What are the essential steps service providers must take to excel in hospitality?",
  //       "What lessons can be learned from successful VIP service experiences for Hajj and Umrah pilgrims?"
  //     ],
  //     ar: [
  //       "كيف يمكن تقديم تجربة عمرة فاخرة تناسب الزوار المميزين الباحثين عن خدمات راقية؟",
  //       "ما الفرص المتاحة في السوق لاستقطاب المسافرين من الفئات الراقية عبر خدمات VIP؟",
  //       "ما الخطوات الأساسية التي يجب على مقدمي الخدمات اتخاذها للتميز في الضيافة؟",
  //       "ما الدروس المستفادة من التجارب الناجحة في تقديم خدمات VIP للحجاج والمعتمرين؟"
  //     ]
  //   },
  //   image: raisingUmrahService,
  //   icon: <RaisingUmrahLevelIcon />,
  //   url: "raising-umrah-service-level"
  // },
  // accommodations
  {
    title: {
      en: "Comprehensive Accommodation (Meeting the Needs of Every Visitor in Medina)",
      ar: "أماكن الإقامة الشاملة (تلبية احتياجات كل زائر في المدينة المنورة)"
    },
    description: {
      en: "Accommodation quality is a key factor in enhancing the experience of pilgrims, and hospitality services in Madinah are influenced by compliance standards, the balance between supply and demand, and regulatory challenges faced by some hotels. This group will discuss hotel compliance with Ministry of Tourism standards, gaps in the hospitality market, challenges hindering the operation of some facilities, and pilgrims, accommodation and hotel service needs. Proposed solutions to enhance compliance and improve the accommodation experience will also be reviewed.",
      ar: `تعد جودة الإقامة عنصرًا أساسيًا في تحسين تجربة ضيوف الرحمن، حيث تتأثر خدمات الضيافة في المدينة المنورة بمعايير الامتثال، والتوازن بين العرض والطلب، والتحديات التنظيمية التي تواجه بعض الفنادق. تركز هذه المجموعة على مناقشة مدى التزام الفنادق بمعايير وزارة السياحة، والفجوة في سوق الضيافة، والتحديات التي تعيق تشغيل بعض المنشآت، بالإضافة إلى استعراض احتياجات المعتمرين من حيث الإقامة والخدمات الفندقية. كما سيتم استعراض الحلول المقترحة لتعزيز الامتثال وتحقيق تجربة إقامة أفضل لضيوف الرحمن.`
    },
    discussion_topics: {
      en: [
        "How does hotel compliance in Makkah and Madinah with Ministry of Tourism standards affect service quality?",
        "How can a balance between supply and demand be achieved in the hospitality market to ensure hotel sustainability?",
        "What are the main challenges faced by non-compliant hotels, and how can they be addressed?",
        "What do pilgrims and visitors look for when choosing accommodation, and how can hotel services be improved to meet their needs?"
      ],
      ar: [
        "كيف يؤثر مدى التزام الفنادق في مكة والمدينة بمعايير وزارة السياحة على جودة الخدمات المقدمة؟",
        "كيف يمكن تحقيق توازن بين العرض والطلب في سوق الضيافة لضمان استدامة تشغيل الفنادق؟",
        "ما أبرز التحديات التي تواجه الفنادق غير المتوافقة مع المتطلبات التنظيمية، وكيف يمكن معالجة هذه المشكلات؟",
        "ما الذي يبحث عنه المعتمرون والزوار عند اختيار أماكن إقامتهم، وكيف يمكن تحسين الخدمات الفندقية لتلبية احتياجاتهم؟"
      ]
    },
    image: accommodations,
    icon: <UmrahAccommodationsIcon />,
    url: "accommodations"
  },
  // // enhancing-umrah-experience-through-technology
  // {
  //   title: {
  //     en: "Enhancing the Umrah Experience Through Technology",
  //     ar: "تعزيز تجربة العمرة من خلال التكنولوجيا"
  //   },
  //   description: {
  //     en: "Technology plays a pivotal role in improving the Umrah experience through digital solutions that facilitate booking, transportation, and crowd management. Smart systems like e-visas, interactive apps, and wearable tech improve pilgrim safety and comfort. Big data and IoT also help optimize service delivery and resource allocation. With the growing reliance on digital solutions, system integration across stakeholders becomes essential for a smoother and safer Umrah journey.",
  //     ar: `تلعب التكنولوجيا دورًا محوريًا في تحسين تجربة العمرة، من خلال حلول رقمية تسهّل الحجز، والتنقل، وإدارة الحشود. تتيح الأنظمة الذكية مثل التأشيرات الإلكترونية، والتطبيقات التفاعلية، والأساور الذكية، تحسين سلامة وراحة المعتمرين. كما تسهم البيانات الضخمة وإنترنت الأشياء في تحسين الخدمات وتوزيع الموارد بكفاءة. مع تزايد الاعتماد على الحلول الرقمية، يصبح تكامل الأنظمة بين الجهات المعنية ضرورة لضمان تجربة عمرة أكثر سلاسة وأمانًا.`
  //   },
  //   discussion_topics: {
  //     en: [
  //       "How can technology simplify the pilgrim journey?",
  //       "What is the role of the Nusuk platform in promoting Umrah, protecting data, and enhancing cybersecurity?",
  //       "How can full digital transformation be applied to Umrah services, and what investment opportunities are available?",
  //       "What tech solutions can be implemented to improve service quality for Umrah pilgrims?"
  //     ],
  //     ar: [
  //       "كيف يمكن أن تساهم التكنولوجيا في تسهيل رحلة المعتمر؟",
  //       "ما هو دور منصة نسك في التسويق للعمرة بشكل أكثر فعالية، وحماية البيانات وتعزيز الأمن السيبراني؟",
  //       "كيف يمكن تطبيق التحول الرقمي الشامل في خدمات العمرة، وما هي فرص الاستثمار المتاحة؟",
  //       "ما الحلول التقنية التي يمكن تطبيقها لرفع جودة الخدمات المقدمة للمعتمرين؟"
  //     ]
  //   },
  //   image: enhancingUmrahExperience,
  //   icon: <EnhancingUmrahIcon />,
  //   url: "enhancing-umrah-experience-through-technology"
  // }
]