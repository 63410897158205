import { Link } from "react-router-dom/cjs/react-router-dom"
import LogoImage from "../assets/logo.png"
import LogoEnImage from "../assets/logo-en.png"
import { GlobalIcon } from "./Icons"
import { useSBSDispatch, useSBSState } from "context/global"
import Locale from "translations";

export default function ZayaraForumHeader() {
  const { locale } = useSBSState();
  const dispatch = useSBSDispatch();

  function changeLanguage() {
    const nextLocale = locale === "ar" ? "en" : "ar"
    dispatch({
      type: "setLocaleWithoutReload",
      payload: nextLocale,
    });
    localStorage.setItem("currentLocale", nextLocale);
    Locale.setLanguage(nextLocale);
  }

  return (
    <div className="container px-0 py-3">
      <div className="d-flex align-items-center justify-content-between gap-10">
        <Link to="/umrah-forum" className="align-self-baseline">
          <img src={locale === "ar" ? LogoImage : LogoEnImage} alt="صفا سوفت منتدي العمرة و الزيار ة" className="img-fluid logo" width="450" />
        </Link>
        <button className="btn language-switcher" onClick={changeLanguage}>
          <GlobalIcon />
          <span>{locale === "ar" ? "English" : "Arabic"}</span>
        </button>
      </div>
    </div>
  )
}
