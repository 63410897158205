import { useSBSDispatch, useSBSState } from "context/global";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import SafaLogo from "../../assets/images/MarketInvoice/SafaLogo.png";
import googleplay from "assets/images/google-play.svg"
import appestore from "assets/images/app-store.svg"
import android from "assets/images/android.png"
import masterImg from "../../assets/images/newLanding/master.png";

export default function NewLandingApps() {
	const { newLand } = Locale;
	const { locale } = useSBSState();
	const dispatch = useSBSDispatch();
	const history = useHistory();




	return (
		<>
			<div className="T-newLanding w-100">
				<div className="newLanding ">
					<div className="">
						<header className="container-custom-land">
							<nav className="navbar navbar-default navbar-fixed dark no-background bootsnav py-3">
								{/* <div className="container"> */}
								<div className="navbar-header">
									<figure className="safa-logo">
										<img src={SafaLogo} alt="" className="img-fluid" />
									</figure>
								</div>
								<div className="attr-nav button">
									<ul className="d-flex justify-content-center alighn-items-center">
										<li
											className="btn-agent-lang pointer mx-2"
											onClick={() => {
												dispatch({
													type: "setLocale",
													payload: locale === "ar" ? "en" : "ar",
												});
												dispatch({ type: "setLoading", payload: true });
											}}
										>
											{locale === "ar" ? "English" : "العربية"}
											<i className="fas fa-flag mx-1"></i>
										</li>
										<li
											className="btn-agent-login pointer"
											onClick={() => history.push("/auth/login")}
										>
											{newLand.agentLogin}
										</li>
									</ul>
								</div>
								{/* </div> */}
							</nav>
						</header>
						<div className="section-1 banner-area with-carousel content-double transparent-nav bg-theme-small normal-text">
								<div style={{display:"contents"}} className="container mx-auto">
								<div className="hide-phone w-1/3 mr-5">
									<img className="w-full" style={{height:"60vh"}} src={android} alt="" srcset="" />
								</div>
									<div className="">
										<h1 >Make Your Business Easy
											<br/> 
											 With Our SBS Mobile App
										</h1>
										<div className="allbtn-box">
											<button
												className=" btn btn-app flex justify-center items-center rounded-lg "
												href="#">
												<img className="w-10" src={googleplay} alt="" />
												<span className="">Google Play Store</span>
											</button>
											<button className=" btn btn-app flex justify-center rounded-lg " href="#" >
												<img className="w-10" src={appestore} alt="" />
												<span className=""> App Store</span>
											</button>
										</div>
								</div>
							
							</div>
						</div>

						<footer className="new-padding bg-light">
							<div className="container-custom-land">
								{/* Start Footer Bottom  */}
								<div className="footer-bottom">
									<div className="col-lg-12">
										<div className="row">
											<div className="col-lg-6">
												<p>{newLand.copyright}</p>
											</div>
											<div className="col-lg-6 text-right link">
												<div className="address-items">
													<ul className="social">
														<li className="facebook mx-3">
															<a
																href="https://www.facebook.com/Safasoftofficial"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-facebook-f"></i>
															</a>
														</li>

														<li className="twitter">
															<a
																href="https://twitter.com/safa_soft"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-twitter"></i>
															</a>
														</li>
														<li className="pinterest">
															<a
																href="https://www.linkedin.com/company/safa-soft/"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-linkedin"></i>
															</a>
														</li>
														<li className="instagram">
															<a
																href="https://www.instagram.com/safasoftofficial/"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-instagram"></i>
															</a>
														</li>
														<li className="youtube">
															<a
																href="https://www.youtube.com/@safasoftofficial"
																target="_blank"
																rel="noreferrer"
															>
																<i className="fab fa-youtube"></i>
															</a>
														</li>
														<li className="instagram"></li>
														<li className="master-card">
															<img src={masterImg} alt="master" width="120" />
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* End Footer Bottom  */}
							</div>
						</footer>
					</div>
				</div>
			</div>
		</>
	);
}
