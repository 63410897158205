import React from "react"
import Locale from 'translations';

export default function UploadImageInput({ imageName, onChange }) {
  const { umarhForum } = Locale;
  return (
    <>

      <div className="d-flex flex-wrap align-items-center justify-content-md-between justify-content-center p-2 w-100 rounded-lg border bg-white gap-10" style={{ borderColor: "#C7C7C7", borderStyle: "dashed" }}>
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.46124 19.3403H5.5412C2.6012 19.1303 1.28125 16.8703 1.28125 14.8603C1.28125 12.8503 2.60121 10.5803 5.49121 10.3803C5.90121 10.3403 6.2612 10.6603 6.2912 11.0803C6.3212 11.4903 6.01125 11.8503 5.59125 11.8803C3.65125 12.0203 2.78125 13.4803 2.78125 14.8703C2.78125 16.2603 3.65125 17.7203 5.59125 17.8603H7.46124C7.87124 17.8603 8.21124 18.2003 8.21124 18.6103C8.21124 19.0203 7.87124 19.3403 7.46124 19.3403Z" fill="#292D32" />
            <path d="M16.6709 19.3402C16.6509 19.3402 16.6409 19.3402 16.6209 19.3402C16.2109 19.3402 15.8309 19.0002 15.8309 18.5902C15.8309 18.1602 16.1509 17.8402 16.5709 17.8402C17.8009 17.8402 18.9009 17.4102 19.7609 16.6402C21.3209 15.2802 21.4209 13.3202 21.0009 11.9402C20.5809 10.5702 19.4109 9.00019 17.3709 8.75019C17.0409 8.71019 16.7809 8.46019 16.7209 8.13019C16.3209 5.73019 15.0309 4.07019 13.0709 3.47019C11.0509 2.84019 8.69086 3.46019 7.22086 5.00019C5.79086 6.49019 5.46087 8.58019 6.29087 10.8802C6.43087 11.2702 6.23092 11.7002 5.84092 11.8402C5.45092 11.9802 5.0209 11.7802 4.8809 11.3902C3.8709 8.57018 4.33091 5.87019 6.14091 3.97019C7.99091 2.03019 10.9609 1.26018 13.5109 2.04018C15.8509 2.76018 17.5009 4.69018 18.1009 7.37018C20.1409 7.83018 21.7809 9.38019 22.4309 11.5202C23.1409 13.8502 22.5009 16.2502 20.7509 17.7702C19.6409 18.7702 18.1909 19.3402 16.6709 19.3402Z" fill="#292D32" />
            <path d="M12 22.2803C9.99 22.2803 8.11002 21.2103 7.08002 19.4803C6.97002 19.3103 6.86002 19.1103 6.77002 18.8903C6.43002 18.1803 6.25 17.3703 6.25 16.5303C6.25 13.3603 8.83 10.7803 12 10.7803C15.17 10.7803 17.75 13.3603 17.75 16.5303C17.75 17.3803 17.57 18.1803 17.21 18.9203C17.13 19.1103 17.02 19.3103 16.9 19.5003C15.89 21.2103 14.01 22.2803 12 22.2803ZM12 12.2803C9.66 12.2803 7.75 14.1903 7.75 16.5303C7.75 17.1503 7.88001 17.7303 8.13001 18.2603C8.21001 18.4303 8.27999 18.5703 8.35999 18.7003C9.11999 19.9903 10.51 20.7803 11.99 20.7803C13.47 20.7803 14.86 19.9903 15.61 18.7203C15.7 18.5703 15.78 18.4303 15.84 18.2903C16.11 17.7403 16.24 17.1603 16.24 16.5403C16.25 14.1903 14.34 12.2803 12 12.2803Z" fill="#292D32" />
            <path d="M11.4289 18.2698C11.2389 18.2698 11.049 18.1998 10.899 18.0498L9.90891 17.0598C9.61891 16.7698 9.61891 16.2898 9.90891 15.9998C10.1989 15.7098 10.6789 15.7098 10.9689 15.9998L11.4489 16.4798L13.0489 14.9998C13.3589 14.7198 13.8289 14.7398 14.1089 15.0398C14.3889 15.3398 14.3689 15.8198 14.0689 16.0998L11.9389 18.0698C11.7889 18.1998 11.6089 18.2698 11.4289 18.2698Z" fill="#292D32" />
          </svg>
          {imageName ?
            <span className="mx-1"> {imageName}</span>
            :
            <>
              <span className="mx-1"> {umarhForum.uploadYourPhoto} </span>
              <span style={{ color: "#979797", fontSize: "12px" }}>
                ({umarhForum.optional})
              </span>
            </>
          }

        </div>

        <label htmlFor="profile-image" className="mb-0 position-relative pointer">
          <button type="button" className="px-5 py-2 bg-white border" style={{ borderColor: "#CBD0DC", borderRadius: "8px" }}>
            {umarhForum.browse}
            <input type="file" id="profile-image" accept="image/*" className="position-absolute w-100 pointer" style={{ inset: "0", opacity: "0" }} onChange={onChange} />
          </button>
        </label>

      </div>
    </>
  );
}
